import { Locale } from '@/i18n/routing';
import { portfolioItemsNL } from './nl';
import { portfolioItemsEN } from './en';

export type PortfolioItemStatic = {
	title: string;
	path: string;
	subtitle?: string;
	type: 'static';
	description: string;
	grade?: string;
	icon?: {
		src: string;
		alt: string;
	};
	images?: {
		src: string;
		alt: string;
		caption?: string;
	}[];
	date: [number, number, number];
	tags: string[];
	links: {
		title: string;
		url: string;
		qr?: {
			isEnabled: boolean;
		};
	}[];
};

export type PortfolioItemInteractive = {
	title: string;
	path: string;
	type: 'interactive';
	description?: string;
	date: [number, number, number];
	icon?: {
		src: string;
		alt: string;
	};
	tags: string[];
	links: {
		title: string;
		url: string;
		qr?: {
			isEnabled: boolean;
		};
	}[];
};

export type PortfolioItem = PortfolioItemStatic | PortfolioItemInteractive;

export const getPortfolioItems = (
	locale: Locale,
): {
	items: PortfolioItem[];
	tags: string[];
} => {
	if (locale === 'nl') {
		return {
			items: portfolioItemsNL,
			tags: getTagsFromPortfolioItems(portfolioItemsNL),
		};
	} else if (locale === 'en') {
		return {
			items: portfolioItemsEN,
			tags: getTagsFromPortfolioItems(portfolioItemsEN),
		};
	}

	return {
		items: [],
		tags: [],
	};
};

export const getPortfolioItemByPath = (locale: Locale, path: string) => {
	const { items } = getPortfolioItems(locale);
	return items.find((item) => item.path === path);
};

// Get all the string tags from portfolio items and every year (put the years first from newest to oldest)
export const getTagsFromPortfolioItems = (items: PortfolioItem[]): string[] => {
	const tags: {
		value: string;
		occurrences: number;
	}[] = [];
	const years: Set<number> = new Set();

	items.forEach((item) => {
		const year = item.date[0];
		years.add(year);
	});

	items.forEach((item) => {
		if ('tags' in item) {
			item.tags.forEach((tag) => {
				const index = tags.findIndex((t) => t.value === tag);
				if (index === -1) {
					tags.push({ value: tag, occurrences: 1 });
				} else {
					tags[index].occurrences++;
				}
			});
		}
	});

	// Sort tags
	tags.sort((a, b) => b.occurrences - a.occurrences);

	return [
		...Array.from(years)
			.sort((a, b) => b - a)
			.map((year) => year.toString()),
		...tags.map((tag) => tag.value),
	];
};
