import { PortfolioItem } from './portfolio';

export const portfolioItemsNL: PortfolioItem[] = [
	{
		title: 'Klokhuis AI Studio',
		path: 'klokhuis-ai-studio',
		subtitle: 'Spelend leren over AI',
		type: 'static',
		description:
			'Voor Het Klokhuis onderzocht en ontwikkelde ik de AI Studio: een laagdrempelige website waar kinderen veilig en spelend leren over kunstmatige intelligentie.\nIn 2021 heb ik een prototype ontwikkeld met één interactief verhaal en een interactieve AI Machine. In deze AI Studio zijn nu 4 interactieve verhalen en nog meer interactieve AI te vinden. Alle AI wordt op je eigen apparaat of anoniem bij Het Klokhuis uitgevoerd.\nHet Klokhuis heeft ook besloten om 4 afleveringen over dit onderwerp te maken. Het gehele project van Klokhuis over AI heeft de Cinekid Award gewonnen voor "Vakmanschap in een Serieproductie".',
		images: [
			{
				src: '/assets/portfolio/klokhuis-ai-studio-1.webp',
				alt: 'Tirsa wijst naar een tablet met de AI Studio website erop. Er staat groot: "Hoe leren computers?" met een startknop. Er zijn veel vrolijke kleuren en objecten om de tablet heen.',
			},
			{
				src: '/assets/portfolio/klokhuis-ai-studio-2.webp',
				alt: 'Het hele Klokhuis team is blij met de Cinekid award. Tirsa houdt deze vast. Het is een abstracte oranje leeuw.',
				caption: 'Cinekid Award',
			},
			{
				src: '/assets/portfolio/klokhuis-ai-studio-3.webp',
				alt: 'Screenshot van de website met tekenblad. Boven staat "Krabbel samen met AI een bloem". Het steeltje en blaadje zijn door de gebruiker getekend en de bloem zelf is door AI getekend.',
				caption: 'Krabbel-Machine',
			},
			{
				src: '/assets/portfolio/klokhuis-ai-studio-4.webp',
				alt: 'In de AI Studio zijn chats met personages van Het Klokhuis. Je kan op chats reageren met opties.',
				caption: 'Interactieve chats',
			},
		],
		date: [2024, 3, 1],
		tags: [
			'AI',
			'Educational',
			'Website',
			'Het Klokhuis',
			'React',
			'Laravel',
		],
		links: [
			{
				title: 'Klokhuis AI Studio',
				url: 'https://aistudio.hetklokhuis.nl/',
			},
		],
	},
	{
		title: 'Schooltv Kaartje Keren',
		path: 'schooltv-kaartje-keren',
		subtitle: 'Leren met Kaartje Keren',
		type: 'static',
		description:
			'Schooltv was op zoek naar een alternatief voor het "Rad van Fortuin". Voorheen gebruikten ze zo\'n rad om verschillende woorden of zinnen te plaatsen die na een draai willekeurig werden uitgelicht. Zo stonden er bijvoorbeeld verschillende stellingen op, waarmee je in de klas discussies kon voeren.\nToch kleefden er een paar nadelen aan dit rad. Zodra er meer "taartpunten" werden toegevoegd, moest de tekst steeds kleiner worden om nog in de vakken te passen. Bovendien zorgde de animatie van de gekleurde vlakken voor te veel flikkeringen op het scherm, wat een toegankelijkheidsprobleem opleverde.\nTijd dus voor een eenvoudig ontwerp dat voor iedereen werkt! Samen met Tessa Kruiger ontwierp ik Kaartje Keren: kaartjes met tekst die je met één klik kunt omdraaien. Zo blijft het idee van willekeurige selectie behouden, maar is het tegelijk overzichtelijk, gebruiksvriendelijk én toegankelijk.',
		icon: {
			src: '/assets/portfolio/kaartje-keren-icon.webp',
			alt: 'Stapel kaartjes',
		},
		images: [
			{
				src: '/assets/portfolio/kaartje-keren-1.webp',
				alt: 'Een stapel kaartjes met een omgekeerd kaartje. Stelling: Ik zeg er altijd wat van als ik zie dat iemand gediscrimineerd wordt. Je kan de stapel schudden.',
			},
			{
				src: '/assets/portfolio/kaartje-keren-2.webp',
				alt: 'Een lijst met open kaartjes met stellingen over discriminatie.',
			},
			{
				src: '/assets/portfolio/kaartje-keren-3.webp',
				alt: 'Een formulier om een stapel kaarten te maken. Je kan de teksten invullen en ook kleuren kiezen.',
			},
		],
		date: [2023, 11, 1],
		tags: ['Schooltv', 'NTR', 'Accessibility', 'React'],
		links: [
			{
				title: 'Kaartje Keren',
				url: 'https://kaartjekeren.schooltv.nl/maken',
			},
		],
	},
	{
		title: 'Podwalks CMS',
		path: 'podwalks-cms',
		subtitle: 'Krachtige tool voor het maken van podwalks',
		type: 'static',
		description:
			'Voor de podwalk-serie "Het water komt" is niet alleen gebruikgemaakt van innovatieve augmented reality, maar is ook het maakproces grondig vernieuwd. Voorheen werden wandelingen gecreëerd met gemeten GPS-punten en audiobestanden die handmatig in de app werden geplaatst. Dit maakte het proces omslachtig en het aanpassen van wandelingen tijdrovend.\nTijd voor verandering! Daarom heb ik voor de NTR het Podwalks CMS ontworpen en ontwikkeld. Dit CMS is live gekoppeld aan de app van "Het water komt", waardoor aanpassingen aan wandelingen direct zichtbaar zijn. Routes kunnen visueel op een kaart worden uitgezet, en dankzij een simulatiefunctie kun je controleren of de timing en aansluiting van audiopunten optimaal zijn.\nDaarnaast biedt het CMS een scala aan geavanceerde functies, zoals rechtenbeheer, meerdere soorten triggers op locatie, ingebouwde API-documentatie voor developers, analytics, routeback-ups, en de mogelijkheid om app-specifieke bestanden te uploaden. Dit alles maakt het niet alleen gebruiksvriendelijk, maar ook flexibel en toekomstbestendig.',
		images: [
			{
				src: '/assets/portfolio/podwalks-cms-2.webp',
				alt: 'Een kaart waarop een route is uitgezet met punten die audio triggers hebben. Deze zijn hier te bewerken.',
			},
			{
				src: '/assets/portfolio/podwalks-cms-1.webp',
				alt: 'Een kaart van Nederland waar verschillende locaties opstaan waar podwalks zijn. Vanaf hier is het mogelijk om een nieuwe podwalk te maken of een bestaande te bewerken.',
			},
			{
				src: '/assets/portfolio/podwalks-cms-3.webp',
				alt: 'Een scherm om de vervoersopties te bewerken van een route. Zo kunnen er parkeerplaatsen en OV haltes worden toegevoegd. Daarnaast ook het adres en een optionele waarschuwing.',
			},
			{
				src: '/assets/portfolio/podwalks-cms-4.webp',
				alt: 'Een interface om het verhaal bij de audio te schrijven. Alle hoofdstukken zijn te selecteren en in het midden bewerk je het geselecteerde hoofdstuk.',
			},
		],
		date: [2023, 9, 1],
		tags: ['Podwalk', 'CMS', 'NTR', 'Laravel', 'React'],
		links: [
			{
				title: 'Het water komt (app)',
				url: 'https://ntr.nl/site/tekst/Het-water-komt---podwalks/131',
				qr: {
					isEnabled: true,
				},
			},
		],
	},
	{
		title: '"Het water komt" prototype',
		path: 'het-water-komt-prototype',
		subtitle: 'Een podwalk met AR',
		type: 'static',
		description:
			'Voor de opkomende serie "Het water komt" wilde het innovatie team van NTR graag onderzoeken of een podwalk in combinatie met AR een goede match zou zijn. Om dit uit te kunnen valideren heb ik in twee weken een prototype ontwikkeld. Met dit prototype kon een podwalk worden gelopen in Marken en waren er AR punten waar een 3D model werd getoond op locatie. Door dit prototype te testen met de doelgroep konden we snel inzichten krijgen in de potentie van deze combinatie.',
		icon: {
			src: '/assets/portfolio/hwk-prototype-icon.webp',
			alt: 'Een locatiepunt met een koptelefoon.',
		},
		images: [
			{
				src: '/assets/portfolio/hwk-prototype-1.webp',
				alt: 'Mensen lopen een podwalk in Marken richting een brug. Er zijn veel groene huizen met oranje daken te zien.',
			},
			{
				src: '/assets/portfolio/hwk-prototype-2.webp',
				alt: 'Mensen lopen een podwalk in Marken langs de kade met boten, waar groene huizen naast staan met witte hekjes.',
			},
		],
		date: [2023, 7, 1],
		tags: [
			'Podwalk',
			'Augmented Reality',
			'NTR',
			'Android',
			'iOS',
			'Flutter',
		],
		links: [
			{
				title: 'Het water komt (app)',
				url: 'https://ntr.nl/site/tekst/Het-water-komt---podwalks/131',
				qr: {
					isEnabled: true,
				},
			},
		],
	},
	{
		title: 'Inclusief Design bij NTR',
		path: 'inclusiefdesign',
		subtitle: 'Voor iedereen, door iedereen',
		type: 'static',
		description:
			'Iedereen moet mee kunnen doen. En daarvoor heb je iedereen nodig.\nInclusief design is mediaontwerp dat iedereen kan waarnemen en begrijpen, ook mensen met een beperking. Omdat ik merkte dat er nog veel aandacht nodig is voor inclusief design bij de publieke omroep en ik vanuit mijn rol niet alles kan oplossen heb ik deze site ontwikkeld.\nOp Inclusief Design kan iedereen kijken hoe je bijdraagt vanuit je eigen rol aan toegankelijke media. Of je nou redacteur bent, UX-ontwerper, grafisch ontwerper, ontwikkelaar, projectleider of innovateur.',
		images: [
			{
				src: '/assets/portfolio/inclusiefdesign-1.webp',
				alt: 'Inclusief Design homepagina. Bovenaan links naar verschillende beroepen, zoals projectleider, redacteur en ontwikkelaar. Onderaan het laatste nieuws over inclusief design, zoals neurodiversiteit pride dag.',
			},
			{
				src: '/assets/portfolio/inclusiefdesign-2.webp',
				alt: 'Pagina\'s vol theorie voor een redacteur. Dit is een inleidende pagina met een checklist: "Voordat je begint". Aan de zijkant zijn er veel pagina\'s over allerlei onderwerpen voor redacteurs, zoals alt teksten en ondertiteling.',
			},
			{
				src: '/assets/portfolio/inclusiefdesign-3.webp',
				alt: 'Een contrastmeter op de site. Je vult een voor- en achtergrondkleur in en krijgt voor iedere lettertypedikte een score en minimale grote. Zo weet je of en hoe je de kleur kunt gebruiken.',
			},
			{
				src: '/assets/portfolio/inclusiefdesign-4.webp',
				alt: '"Aan de slag" pagina voor innovateur. Een pagina over hoe je de eerste brainstorm moet opzetten en wat er allemaal nodig is om te beginnen.',
			},
		],
		date: [2023, 5, 1],
		tags: ['Accessibility', 'NTR'],
		links: [
			{
				title: 'Inclusief Design bij NTR',
				url: 'https://inclusiefdesign.ntr.nl',
				qr: {
					isEnabled: true,
				},
			},
		],
	},
	{
		title: 'SoortSafari prototype',
		path: 'soortsafari-prototype',
		subtitle: 'Spelend leren over de natuur om je heen',
		type: 'static',
		description:
			'Het Klokhuis was op zoek naar een slimme manier om kinderen te laten leren over de natuur om hen heen. Het concept van SoortSafari is voortgekomen uit mijn eerdere onderzoeken bij NTR en werd geïnspireerd door de Dierenzoeker van Het Klokhuis. Met die tool konden kinderen dieren determineren die ze hadden gevonden.\nIn SoortSafari werkt het precies andersom: de app stuurt je op pad met een opdracht. Het prototype bestond uit een kalender en een kwartettenoverzicht met plant- en diersoorten. Om een soort te verzamelen maak je een foto, waarna AI bepaalt of je de juiste soort hebt gevonden. Zo kun je een complete verzameling aanleggen en spelenderwijs meer leren over de natuur om je heen.\nHet prototype bleek een groot succes: kinderen waren na 45 minuten nog niet klaar en klommen zelfs op elkaar om een betere foto te maken van een duif in een boom. Met alle inzichten uit de observaties zijn we aan de slag gegaan met het ontwikkelen van een productieversie.',
		images: [
			{
				src: '/assets/portfolio/soortsafari-prototype-1.webp',
				alt: 'Een kind maakt een foto van een worm en de foto wordt gescand.',
			},
			{
				src: '/assets/portfolio/soortsafari-prototype-2.webp',
				alt: 'Twee kinderen proberen soorten te fotograferen. Er is een telefoon in beeld met kwartetten van de soorten. Sommige hebben een door het kind gemaakte foto op de kwartetkaart en zijn dus verzameld.',
			},
			{
				src: '/assets/portfolio/soortsafari-prototype-3.webp',
				alt: 'Kinderen klimmen op elkaar om een foto van een vogel in een boom te kunnen maken.',
			},
		],
		date: [2023, 2, 1],
		tags: [
			'AI',
			'Android',
			'iOS',
			'Website',
			'Education',
			'NTR',
			'Het Klokhuis',
			'React',
		],
		links: [
			{
				title: 'SoortSafari',
				url: 'https://hetklokhuis.nl/dossier/169/soortsafari',
				qr: {
					isEnabled: true,
				},
			},
			{
				title: 'Dierenzoeker',
				url: 'https://www.dierenzoeker.nl/',
			},
		],
	},
	{
		title: 'Klokhuis AI Studio prototype',
		path: 'klokhuis-ai-studio-prototype',
		subtitle: 'Spelend leren over AI',
		type: 'static',
		description:
			'AI is iets dat steeds meer voorkomt en steeds meer invloed heeft op ons dagelijks leven, maar waar de meeste mensen nog niet goed van weten wat het is. Als afstudeerproject heb ik deze AI Studio ontwikkeld waarin kinderen over AI leren en hun eigen AI trainen. Ik wilde onderzoeken hoe we door middel van spelend leren de volgende generatie meer grip konden geven op het onderwerp kunstmatige intelligentie. Daarbij heb ik een interactieve dialoog als medium onderzocht, waarin de verschillende personages van Het Klokhuis je meenemen in de theorie. Met dit onderzoek ben ik afgestudeerd met twee keer een 9,3 en een 9,4. Het gemiddelde cijfer van mijn bachelorvakken is een 8,7.',
		grade: '9,3',
		images: [
			{
				src: '/assets/portfolio/klokhuis-ai-1.webp',
				alt: 'Een screenshot van de AI Machine waarmee je een AI kan trainen die beelden herkend. Deze AI wordt met handgebaren getraind.',
			},
			{
				src: '/assets/portfolio/klokhuis-ai-2.webp',
				alt: 'Meerdere kinderen spelen met de website.',
			},
			{
				src: '/assets/portfolio/klokhuis-ai-3.webp',
				alt: 'Meerdere kinderen spelen met de website.',
			},
		],
		date: [2022, 7, 1],
		tags: [
			'AI',
			'Educational',
			'Website',
			'Het Klokhuis',
			'React',
			'Laravel',
		],
		links: [
			{
				title: 'Klokhuis AI Studio Prototype',
				url: 'https://www.hetklokhuis.nl/aiprototype',
			},
		],
	},
	{
		title: 'Schooltv Voice Actions',
		path: 'schooltv-voice-actions',
		subtitle: 'Ga in gesprek met historische figuren',
		type: 'static',
		description:
			'Schooltv heb ik geholpen met nieuwe ontwerpen voor voice actions om deze nog gebruiksvriendelijker te maken. Met de voice actions kunnen leerlingen praten met verschillende historische figuren, zowel via spraak als via typen.',
		images: [
			{
				src: '/assets/portfolio/schooltv-voice-1.webp',
				alt: 'Screenshot van telefoon met gesprek met Willem van Oranje. Op het scherm zie je tekst van wat hij zegt. Je kan reageren met typen of met je stem.',
			},
			{
				src: '/assets/portfolio/schooltv-voice-2.webp',
				alt: 'Screenshot van laptop met gesprek met Willem van Oranje. Op het scherm zie je tekst van wat hij zegt. Je kan reageren met typen of met je stem.',
			},
		],
		date: [2022, 5, 1],
		tags: ['Voice', 'Schooltv', 'NTR'],
		links: [
			{
				title: 'NPO Innovatie',
				url: 'https://npo.nl/innovatie/projecten/ga-in-gesprek-met-willem-van-oranje#download-validatie-vervolg-schoolttv-voice-action',
			},
			{
				title: 'Schooltv prototype',
				url: 'https://schooltv.nl/link/ga-in-gesprek-met-willem-van-oranje-de-vader-des-vaderlands/',
			},
		],
	},
	{
		title: 'Klokhuis Scanner',
		path: 'klokhuis-scanner',
		subtitle: 'Kan jij ze allemaal verzamelen?',
		type: 'static',
		description:
			'Wist je dat je in huis triljoenen onzichtbare huisdieren hebt? Maar wie zijn dat? Mogen ze blijven? Voor NPO Innovatie deed ik onderzoek naar het inzetten van WebAR om verhalen voor de jeugd meer impact te geven. Met de Klokhuis Scanner ontmoet je wel 10 van jouw onzichtbare huisdieren.',
		grade: '10',
		icon: {
			src: '/assets/portfolio/klokhuis-scanner.webp',
			alt: 'Een appeltje met streep van licht van een scanner eroverheen',
		},
		images: [
			{
				src: '/assets/portfolio/klokhuisscanner-beerdiertje.webp',
				alt: 'Een telefoon met camera die een plant herkent. Hier komt het beerdiertje uit.',
			},
			{
				src: '/assets/portfolio/klokhuisscanner-giardia.webp',
				alt: 'Een telefoon met camera die een kat herkent. Hier komt de giardia uit.',
			},
			{
				src: '/assets/portfolio/klokhuisscanner-huidbacterie.webp',
				alt: 'Een telefoon met camera die een mens herkent. Hier komt de huidbacterie uit.',
			},
			{
				src: '/assets/portfolio/klokhuisscanner-huisstofmijt.webp',
				alt: 'Een telefoon met camera die een bank herkent. Hier komt het huisstofmijt uit.',
			},
			{
				src: '/assets/portfolio/klokhuisscanner-penseelschimmel.webp',
				alt: 'Een telefoon met camera die een appel herkent. Hier komt de penseelschimmel uit.',
			},
		],
		date: [2021, 10, 1],
		tags: [
			'Augmented Reality',
			'Educational',
			'Website',
			'Het Klokhuis',
			'React',
			'AI',
		],
		links: [
			{
				title: 'Klokhuis Scanner',
				url: 'https://www.hetklokhuis.nl/klokhuisscanner',
			},
			{
				title: 'NPO Innovatie',
				url: 'https://npo.nl/innovatie/projecten/onzichtbare-huisdieren',
			},
			{
				title: 'LinkedIn post',
				url: 'https://www.linkedin.com/feed/update/urn:li:activity:6850726256124669952/',
			},
		],
	},
	{
		title: 'Verrekijker Rijksmuseum Boerhaave',
		path: 'verrekijker-rijksmuseum-boerhaave',
		subtitle: 'Rijksmueum Boerhaave in Leiden',
		type: 'static',
		description:
			'Met dit prototype onderzochten we de mogelijkheden van AR in een speeltuinobject voor kinderen. Door de verrekijker heen zie je een extra laag over de binnentuin. Met deze elementen kan je interacteren met de hendel onder de kijker. Het studieproject is samen met Maisie Klaassen, Marlène Zwetsloot en Irene de Koning ontwikkeld.',
		grade: '8,9',
		icon: {
			src: '/assets/portfolio/boerhaave.webp',
			alt: 'Logo van museum Boerhaave: Een grote letter B',
		},
		images: [
			{
				src: '/assets/portfolio/verrekijker-1.webp',
				alt: 'De speeltuin van het museum in de binnentuin. Er is een augmented reality bril in beeld waardoor je een extra laag kan zien. De bril heeft een draaihendel.',
			},
			{
				src: '/assets/portfolio/verrekijker-2.webp',
				alt: 'De binnentuin met een digitale laag eroverheen. Archimedes vertelt: "Ik leefde meer dan 2000 jaar geleden" en staat naast de schroef van Archimedes.',
			},
			{
				src: '/assets/portfolio/verrekijker-3.webp',
				alt: 'De binnentuin met een digitale laag eroverheen. Je kan hier je eigen familiewapen maken door verschillende keuzes te maken.',
			},
		],
		date: [2022, 1, 1],
		tags: ['Augmented Reality', 'Educational', 'Hardware', 'Godot'],
		links: [
			{
				title: 'Waterland Boerhaave',
				url: 'https://rijksmuseumboerhaave.nl/te-zien-te-doen/waterland-boerhaave/',
			},
		],
	},
	{
		title: "Saibo's Lab 2",
		path: 'saibos-lab-2',
		subtitle: 'Jouw vrolijke virtuele docent!',
		type: 'static',
		description:
			'Saibo is de super behulpzame virtuele docent die jou helpt met de regels voor labveiligheid. Om het onderwijs te verrijken ontwikkelden we in dienst van de Hogeschool Leiden een virtual reality app voor de opleiding Biologie en Medisch labonderzoek. De app zal ook in gebruik worden genomen door mboRijnland en middelbare scholen. In samenwerking met Marlène Zwetsloot en Kim Hoogland is deze app ontwikkeld.',
		icon: {
			src: '/assets/portfolio/saibo.webp',
			alt: 'Saibo is een zwevende schattige bol met ogen',
		},
		images: [
			{
				src: '/assets/portfolio/labveiligheid-1.webp',
				alt: 'Saibo heet je welkom bij de les over afvalverwerking van biologie. Saibo zweeft naast een whiteboard en voor je is een tafel uit het lab.',
			},
			{
				src: '/assets/portfolio/labveiligheid-2.webp',
				alt: 'Keuze uit verschillende lessen: een inleidende les en verschillende afvalverwerkingslessen',
			},
			{
				src: '/assets/portfolio/labveiligheid-3.webp',
				alt: 'Verschillende afvalvaten staan naast de tafel in het lab.',
			},
			{
				src: '/assets/portfolio/labveiligheid-4.webp',
				alt: 'Naast de afvalvaten in het lab hangt een schema waar op staat hoe je vloeibaar afval moet weggooien.',
			},
		],
		date: [2021, 8, 1],
		tags: [
			'Virtual Reality',
			'Educational',
			'Website',
			'Android',
			'iOS',
			'Godot',
		],
		links: [
			{
				title: 'Labveiligheid.nl',
				url: 'https://www.labveiligheid.nl/',
			},
			{
				title: 'Play Store',
				url: 'https://play.google.com/store/apps/details?id=nl.labveiligheid',
			},
			{
				title: 'App Store',
				url: 'https://apps.apple.com/nl/app/labveiligheid/id1588125934',
			},
		],
	},
	{
		title: 'NPO Kennis Toegankelijkheid',
		path: 'npo-kennis-toegankelijkheid',
		subtitle: 'NPO Kennis... En je weet het!',
		type: 'static',
		description:
			'Bij NTR hielp ik om de toegankelijkheid te verbeteren van componenten op de website van NPO Kennis. Ik analyseerde knelpunten en implementeerde oplossingen die hielpen om de website toegankelijk te maken voor mensen met een beperking, zoals visuele handicaps.',
		icon: {
			src: '/assets/portfolio/npokennis.webp',
			alt: 'Logo van NPO Kennis met de woorden NPO en Kennis in ruitjes.',
		},
		date: [2021, 10, 1],
		tags: ['React', 'Website', 'Accessibility'],
		links: [
			{
				title: 'NPO Kennis',
				url: 'https://www.npokennis.nl/',
			},
		],
	},
	{
		title: 'MotionSynth',
		path: 'motionsynth',
		type: 'static',
		description:
			'Maak muziek met je handen! MotionSynth is een interactief experiment waarbij je muziek maakt door je handen te bewegen. Probeer het uit met je webcam!',
		icon: {
			src: '/assets/portfolio/motionsynth-icon.webp',
			alt: 'Een cirkel met verschillende kleuren die in elkaar overlopen: roze, blauw en groen',
		},
		images: [
			{
				src: '/assets/portfolio/motionsynth.gif',
				alt: 'Ik beweeg mijn handen waardoor er gekleurde bollen ontstaan op de plek van mijn handen.',
			},
		],
		date: [2020, 11, 1],
		tags: ['Website', 'AI', 'Music'],
		links: [
			{
				title: 'MotionSynth',
				url: 'https://motionsynth.web.app/',
			},
		],
	},
	{
		title: 'Gloves',
		path: 'gloves',
		type: 'static',
		description:
			'Tijdens dit project hebben Marlène Zwetsloot en ik prototypen handschoenen ontwikkeld die als instrument gebruikt konden worden. Met AI konden eigen gebaren worden getraind die vervolgens met ieder muziekprogramma verbonden konden worden.',
		grade: '8,1',
		images: [
			{
				src: '/assets/portfolio/gloves-2.gif',
				alt: 'Een handschoen met draden en chips erop. In de achtergrond is een laptop met een muziekprogramma open.',
			},
			{
				src: '/assets/portfolio/gloves-3.webp',
				alt: 'Verschillende acties van de handschoenen, zoals je wijsvinger draaien, die verbonden zijn aan muzikale effecten.',
			},
			{
				src: '/assets/portfolio/gloves-1.webp',
				alt: 'De handschoen van dichtbij. Iedere vinger heeft een buigsensor en er is een bewegingssensor in het midden.',
			},
		],
		date: [2020, 11, 1],
		tags: ['Hardware', 'Music', 'AI', 'Flutter'],
		links: [],
	},
	{
		title: 'Laze',
		path: 'laze',
		subtitle: 'Ontspannen is belangrijk!',
		type: 'static',
		description:
			"Laze helpt jou met je productiviteitscyclus. Relaxen is belangrijk, daarom kan Laze je ook nog helpen herinneren om op tijd te stoppen met werken 's avonds. Gemaakt samen met Maisie Klaassen als schoolproject.",
		grade: '8,3',
		icon: {
			src: '/assets/portfolio/laze-icon.webp',
			alt: 'Witte cirkel op paarse achtergrond',
		},
		images: [
			{
				src: '/assets/portfolio/laze-1.webp',
				alt: 'Iemand kijkt blij naar een telefoon. Laze zegt: "Awesome!"',
			},
			{
				src: '/assets/portfolio/laze-2.webp',
				alt: 'Iemand zit achter een laptop. De timer op de telefoon zegt nog 17 minuten productieve tijd.',
			},
			{
				src: '/assets/portfolio/laze-3.webp',
				alt: 'Het logo van Laze met de tekst: "Hi, I\'m Laze.',
			},
		],
		date: [2020, 8, 1],
		tags: ['Android'],
		links: [
			{
				title: 'Laze',
				url: 'https://play.google.com/store/apps/details?id=net.collaby.laze',
			},
		],
	},
	{
		title: 'Manta',
		path: 'manta',
		type: 'interactive',
		description:
			'Een abstracte vorm beweegt geïnspireerd door de manta rog.',
		date: [2020, 8, 1],
		icon: {
			src: '/assets/portfolio/manta.webp',
			alt: 'Twee zwevende abstracte vormen in groen en paars.',
		},
		tags: ['Creative Coding', 'Website'],
		links: [
			{
				title: 'Manta',
				url: '/assets/art/manta/index.html',
			},
		],
	},
	{
		title: 'Tonest',
		path: 'tonest',
		type: 'static',
		description:
			'Tonest helpt je met oefenen om zuiver te zingen of je instrument te stemmen. Tonest is gemaakt in Flutter en is te downloaden in Google Play. De app komt met history graph en ingebouwde piano (met alle octaven).',

		images: [
			{
				src: '/assets/portfolio/tonest-1.webp',
				alt: 'Screenshots van de app waarbij de app aangeeft welke noot er hoorbaar is en hoe zuiver. Er is een grafiek met de geschiedenis van de noten en een piano om geluid te maken.',
			},
			{
				src: '/assets/portfolio/tonest-2.webp',
				alt: 'Het tonest logo is het woord uitgeschreven met strepen erachter.',
			},
			{
				src: '/assets/portfolio/tonest-3.webp',
				alt: 'Het simpele tonest logo is alleen de letter T met strepen erachter.',
			},
		],
		date: [2020, 9, 1],
		tags: ['Flutter', 'Android', 'Music'],
		links: [
			{
				title: 'Play Store',
				url: 'https://play.google.com/store/apps/details?id=net.collaby.tonest',
			},
		],
	},
	{
		title: 'PLNT Tiny Offices',
		path: 'plnt-tiny-offices',
		type: 'static',
		description:
			'Met dit project voor TheField kunnen klanten gemakkelijk online een Tiny Office reserveren. De offices zijn gebruiksvriendelijk te managen voor TheField. Gemaakt met Laravel en React. Ontwikkelt als schoolproject in opdracht van TheField.',
		grade: '10',

		images: [
			{
				src: '/assets/portfolio/thefield-1.webp',
				alt: "Website met detailpagina over kamer Lockerroom. Er zijn foto's en je kan de kamer reserveren.",
			},
			{
				src: '/assets/portfolio/thefield-2.webp',
				alt: 'Overzicht van alle kamers op de site met prijzen, reviews en informatie.',
			},
			{
				src: '/assets/portfolio/thefield-3.webp',
				alt: "Website met detailpagina over kamer Fri-Dom. Er zijn foto's en je kan de kamer reserveren.",
			},
		],
		date: [2020, 7, 1],
		tags: ['Laravel', 'React', 'Website'],
		links: [
			{
				title: 'Demo',
				url: 'https://thefield.collaby.net/',
			},
		],
	},
	{
		title: 'Karipuna',
		path: 'karipuna',
		subtitle: 'Gebaseerd op een waargebeurd verhaal',
		type: 'static',
		description:
			'Beleef de sfeer van dit deel van het Amazonewoud, terwijl je leert over de situatie en zelf de loop van het verhaal beïnvloedt. Begeleid André in de beslissingen die hij moet nemen om zijn volk te redden. Een interactief nieuwsverhaal voor een studieproject.',
		grade: '8',
		images: [
			{
				src: '/assets/portfolio/karipuna-1.webp',
				alt: 'Screenshot van de site met titel André Karipuna (hoofdstuk 1). Je moet klikken om het verhaal te lezen.',
			},
			{
				src: '/assets/portfolio/karipuna-2.webp',
				alt: "Screenshot van de site van het einde van het verhaal. Er staat actuele informatie met foto's van het gebied en een call-to-action om een handtekening te zetten.",
			},
		],
		date: [2019, 11, 1],
		tags: ['Educational', 'Website'],
		links: [
			{
				title: 'Beleef het verhaal',
				url: 'https://karipuna.web.app/',
			},
		],
	},
	{
		title: 'Flock',
		path: 'flock',
		type: 'interactive',
		description:
			'Interactief experiment. Typ in het vlak om te beginnen en sleep met je muis. Jouw creatie leeft en beweegt als een zwerm vogels in de natuur.',
		icon: {
			src: '/assets/portfolio/flock.webp',
			alt: 'Een aantal keer het woord "Flock" die samen vliegen net als een zwerm vogels.',
		},
		date: [2020, 8, 1],
		tags: ['Creative Coding', 'Website'],
		links: [
			{
				title: 'Flocking text',
				url: '/assets/art/type/index.html',
			},
		],
	},
	{
		title: 'Dinoh',
		path: 'dinoh',
		type: 'static',
		description:
			'Speel familiespellen op de tv! Gebruik je Android telefoon om mee te doen en verbind met je Chromecast.',
		images: [
			{
				src: '/assets/portfolio/dinoh-1.webp',
				alt: 'Telefoons en TV. Op tv is een tekening te zien. De ene telefoon tekent en de andere telefoon moet raden wat het is. Het lijkt op een beer.',
			},
			{
				src: '/assets/portfolio/dinoh-2.webp',
				alt: "Telefoons en TV. Op TV zijn auto's te zien die een stuk moeten rijden. Op telefoons moeten spelers op de juiste knoppen drukken om de auto vooruit te laten gaan.",
			},
			{
				src: '/assets/portfolio/dinoh-3.webp',
				alt: 'Telefoons en TV. Op de TV wordt langzaam een dier zichtbaar. Spelers moeten raden welk dier dit is.',
			},
		],
		date: [2018, 11, 1],
		tags: ['Chromecast', 'Android'],
		links: [
			{
				title: 'Play Store',
				url: 'https://play.google.com/store/apps/details?id=nl.birdssoftware.dinoh',
			},
		],
	},
	{
		title: "Saibo's Lab 1",
		path: 'saibos-lab-1',
		type: 'static',
		description:
			'Een virtual reality applicatie voor de opleiding Biologie en Medisch Laboratoriumonderzoek. Ontwikkelt met Marlène Zwetsloot en Kim Hoogland als studieproject.',
		grade: '9,1',
		images: [
			{
				src: '/assets/portfolio/saibo-1.webp',
				alt: 'Een labtafel in een leslokaal. Een virtuele assistent geeft les.',
			},
			{
				src: '/assets/portfolio/saibo-2.webp',
				alt: 'Instellingen van de VR-app. Je kan het geluid aan en uitzetten, maar ook de snor van de virtuele assistent.',
			},
		],
		date: [2020, 2, 1],
		tags: ['Virtual Reality', 'Educational', 'Website'],
		links: [
			{
				title: 'Prototype',
				url: 'https://saibolab.web.app/',
			},
		],
	},
	{
		title: 'Mondriaan',
		path: 'mondriaan',
		type: 'interactive',
		description:
			'Op de site en klik om een unieke Mondriaan te laten genereren.',
		icon: {
			src: '/assets/portfolio/mondriaan.webp',
			alt: 'Mondriaan plaatje: rechte vlakken in wit, rood, blauw en geel.',
		},
		date: [2020, 8, 1],
		tags: ['Creative Coding', 'Website'],
		links: [
			{
				title: 'Mondriaan',
				url: '/assets/art/mondriaan/index.html',
			},
		],
	},
	{
		title: 'VR Pixel Paint',
		path: 'vr-pixel-paint',
		type: 'static',
		description:
			'Een virtual reality app gemaakt met A-Frame. Werkt op Google Cardboard. Je kan pixel art maken en zelfs kleuren mengen!',

		images: [
			{
				src: '/assets/portfolio/vrdraw-1.webp',
				alt: 'Een VR-canvas waar op te kleuren is met vierkantjes.',
			},
			{
				src: '/assets/portfolio/vrdraw-2.webp',
				alt: 'Een ingekleurd canvas met strakke verticale banen in regenboogkleuren.',
			},
			{
				src: '/assets/portfolio/vrdraw-3.webp',
				alt: 'Een meng-optie. Twee kleuren stop je in een trechter, waaruit een nieuwe kleur komt.',
			},
		],
		date: [2019, 11, 1],
		tags: ['Virtual Reality', 'Website'],
		links: [
			{
				title: 'Uitproberen',
				url: 'https://tijmen-vr.web.app/draw/',
			},
		],
	},
	{
		title: 'VR UFO',
		path: 'vr-ufo',
		type: 'static',
		description:
			'Laat een UFO landen in Virtual Reality. Gemaakt in A-Frame voor Google Cardboard.',

		images: [
			{
				src: '/assets/portfolio/ufo-1.webp',
				alt: 'Een controller, planeet en UFO. De controller geeft de tekst: "Pull lever"',
			},
			{
				src: '/assets/portfolio/ufo-2.webp',
				alt: 'De UFO vliegt weg van de planeet.',
			},
			{
				src: '/assets/portfolio/ufo-3.webp',
				alt: 'De UFO is geland op de planeet.',
			},
		],
		date: [2019, 11, 1],
		tags: ['Virtual Reality'],
		links: [
			{
				title: 'Uitproberen',
				url: 'https://tijmen-vr.web.app/ufo/',
			},
		],
	},
	{
		title: 'Roasted Mirchi',
		path: 'roasted-mirchi',
		type: 'static',
		description:
			'De website voor production house Roasted Mirchi uit Mumbai. Roasted Mirchi fotografeert en produceert ook muziek.',
		images: [
			{
				src: '/assets/portfolio/roasted-mirchi.webp',
				alt: "Website met een grote rode cirkel met links erlangs. Links naar films, talent, studio's en meer.",
			},
			{
				src: '/assets/portfolio/roasted-mirchi-2.webp',
				alt: "Website met een grote rode cirkel met links erlangs. Links naar films, talent, studio's en meer.",
			},
		],
		date: [2019, 5, 1],
		tags: ['Website'],
		links: [
			{
				title: 'Website',
				url: 'http://roastedmirchi.com/',
			},
		],
	},
	{
		title: 'MuseBlocks',
		path: 'museblocks',
		type: 'static',
		description:
			'Iedereen kan muziek maken met de blocks in MuseBlocks. Gemaakt als schoolproject, maar nooit gepubliceerd. Neem contact op om meer te weten te komen!',
		icon: {
			src: '/assets/portfolio/museblocks-icon.webp',
			alt: 'Muzieknootjes',
		},
		date: [2019, 11, 1],
		tags: ['Flutter', 'Music'],
		links: [],
	},
	{
		title: 'Ethereal',
		path: 'ethereal',
		type: 'static',
		description:
			'Watch faces voor Wear OS smartwatches. Niet langer beschikbaar in de Play Store.',
		images: [
			{
				src: '/assets/portfolio/ethereal-1.webp',
				alt: 'Korte wijzers met een grote "10" in beeld die het uur aangeeft. Ook is het batterijpercentage te zien. In verschillende kleuren.',
			},
			{
				src: '/assets/portfolio/ethereal-2.webp',
				alt: 'Minimalistische analoge wijzers. De korte wijzer schijnt een lampje op het uur, dat daar ook met een cijfer is aangegeven. In verschillende kleuren.',
			},
			{
				src: '/assets/portfolio/ethereal-3.webp',
				alt: 'Drie verschillende minimalistische analoge klokken met groene accentkleuren.',
			},
			{
				src: '/assets/portfolio/ethereal-4.webp',
				alt: "Digitale klokken met verschillende foto's van bloemen. De tijd staat er met grote cijfers overheen.",
			},
			{
				src: '/assets/portfolio/ethereal-5.webp',
				alt: 'Meerdere abstracte wijzerplaten. Eentje lijkt op een heelal. Twee hebben grote vormen die meebewegen met de tijd. Een ander heeft een analoge klok, maar die is half afgesneden.',
			},
		],
		date: [2018, 6, 1],
		tags: ['Wear OS', 'Android'],
		links: [],
	},
];
